import React from "react"

const IntlContext = React.createContext()
export const useGatsbyIntl = () => React.useContext(IntlContext);

export const IntlContextProvider = IntlContext.Provider
export const IntlContextConsumer = IntlContext.Consumer


export const IntlLanguageMapContext = React.createContext()
export const useLangMap = () => React.useContext(IntlLanguageMapContext);
