module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/tesders/gatsby-web/src/components/layout/index"},
    },{
      plugin: require('../plugins/gatsby-plugin-intl-custom/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/tesders/gatsby-web/src/lang","languages":["en","de"],"defaultLanguage":"de","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.tesders.de","siteUrl":"https://tesders.de"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
