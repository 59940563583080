import React, {useContext} from "react";

interface SmartNavigationContextProps {
    goToHome: (elem: string) => void,
    goToBottom: () => void,
}

export const SmartNavigationContext = React.createContext<SmartNavigationContextProps>({
    goToHome: () => null,
    goToBottom: () => null
})

export const useSmartNavigation = () => useContext(SmartNavigationContext);
