import React, {useContext} from "react"
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"
import {IntlContextConsumer, IntlLanguageMapContext, useLangMap} from "./intl-context"

const Link = React.forwardRef(
  ({ to, language, children, onClick, ...rest }, ref) => (
      <IntlLanguageMapContext.Consumer>
        {(langMap) =>
          <IntlContextConsumer>
            {(intl) => {
              const languageLink = language || intl.language

              const mapHit = langMap?.find(e => to.match(new RegExp(`^${e.url_de}/?$`)));
              let link;
              if (! mapHit) {
                link = to
              } else {
                link = languageLink === 'de' ? mapHit.url_de : mapHit.url_en;
              }

              const handleClick = (e) => {
                if (language) {
                  localStorage.setItem("gatsby-intl-language", language)
                }
                if (onClick) {
                  onClick(e)
                }
              }

              return (
                <GatsbyLink {...rest} to={link} onClick={handleClick} ref={ref}>
                  {children}
                </GatsbyLink>
              )
            }}
          </IntlContextConsumer>
        }
      </IntlLanguageMapContext.Consumer>
  )
)

export default Link

export const navigate = (to, options) => {
  if (typeof window === "undefined") {
    return
  }

  const { language, routed, allUrls } = window.___gatsbyIntl
  const link = routed ? `/${language}${to}` : `${to}`

  const mapHit = allUrls?.find(e => to.match(new RegExp(`^${e.url_de}/?$`)));
  const dest = language === 'de' ? mapHit.url_de : mapHit.url_en;

  gatsbyNavigate(dest, options)
}

export const changeLocale = (language, to) => {
  if (typeof window === "undefined") {
    return
  }
  const { routed, languageMappings } = window.___gatsbyIntl

  const removePrefix = (pathname) => {
    const base =
      typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
    if (base && pathname.indexOf(base) === 0) {
      pathname = pathname.slice(base.length)
    }
    return pathname
  }

  const removeLocalePart = (pathname) => {
    if (!routed) {
      return pathname
    }
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const pathname =
    to || removeLocalePart(removePrefix(window.location.pathname))

  if (languageMappings) {
    const link = `${languageMappings[language]}${window.location.search}`
    localStorage.setItem("gatsby-intl-language", language)
    gatsbyNavigate(link)
  } else {
    // TODO: check slash
    const link = `/${language}${pathname}${window.location.search}`
    localStorage.setItem("gatsby-intl-language", language)
    gatsbyNavigate(link)
  }

}
