import React from "react";
import {useIntl} from "react-intl";
import {thru} from "lodash";

export function useTrans() {
    
    const intl = useIntl();
    
    
    return {
        locale: intl.locale,
        trans: (params) => {
            return thru(intl.formatMessage(params), (message) => {
              
                return false
                    ? `$${message}$`
                    : message;
                
            })
        }
    }
    
}

const Trans: React.FC<{id: string} & {[p: string]: any}> = (
    {
        id,
        ...rest
    }
) => {
    
    const {trans} = useTrans()
    return (<>
        {trans({id, ...rest})}
    </>)
}

export default Trans;
