import React from "react"
import browserLang from "browser-lang"
import {graphql, useStaticQuery, withPrefix} from "gatsby"
import {IntlProvider} from "react-intl"
import {IntlContextProvider, IntlLanguageMapContext} from "./intl-context"

const withIntlProvider = (intl, langUrls) => (children) => {
    return (
        <IntlProvider
            locale={intl.language}
            defaultLocale={intl.defaultLanguage}
            messages={intl.messages}
        >
            <IntlLanguageMapContext.Provider value={langUrls}>
                <IntlContextProvider value={intl}>
                    {children}
                </IntlContextProvider>
            </IntlLanguageMapContext.Provider>
        </IntlProvider>
    )
}

export default ({element, props}, pluginOptions) => {
    if (!props) {
        return
    }

    const {pageContext, location} = props
    const {intl, sourcedLangUrls, originalPath} = pageContext
    const {language, languages, allUrls: finalLangUrls} = intl

    if (typeof window !== "undefined") {
        window.___gatsbyIntl = intl

        // const storedLang = window.localStorage.getItem("gatsby-intl-language")
        // const detectedLang = browserLang({
        //     languages,
        //     fallback: language,
        // })

        // if (!storedLang
        //     && detectedLang !== language
        //     && languages.includes(detectedLang)) {
        //     const {search} = location
        //
        //     // Skip build, Browsers only
        //     if (typeof window !== "undefined") {
        //         let detected = storedLang || detectedLang
        //
        //         const queryParams = search || ""
        //         const mapHit = finalLangUrls?.find(e => originalPath.match(new RegExp(`^${e.url_de}/?$`)));
        //         const newUrl = withPrefix(`${mapHit[`url_${detectedLang}`]}${queryParams}`)
        //         window.localStorage.setItem("gatsby-intl-language", detected)
        //         window.location.replace(newUrl)
        //     }
        // } else {
        //     window.localStorage.setItem("gatsby-intl-language", language)
        // }
        window.localStorage.setItem("gatsby-intl-language", language)
    }

    return withIntlProvider(intl, finalLangUrls)(element)
}
