import classNames from "classnames";
import * as containerStyles from "@/src/components/layout/style.module.scss";
import React, {useEffect, useState} from "react";
import {useTrans} from "../trans";
import debounce from "loadsh/debounce";
import FullsizeMenu from "@/src/components/fullsize-menu/fullsize-menu";
import Link from "@/plugins/gatsby-plugin-intl-custom/link"

const NavigationHome = React.forwardRef<HTMLElement, any>(
    ({
         goToHome,
         goToBottom
     }, ref) => {
    
    const [menuState, setMenuState] = useState({
        leistung: false,
        projekte: false
    })
    
    useEffect(() => {
        const onScroll = () => {
            const i = ['leistung', 'projekte'].reverse().findIndex(select => {
                const elem = document.getElementById(select);
                return elem && window.scrollY
                    > window.pageYOffset + elem.getBoundingClientRect().y - 200;
            })
            const real = 2 - i - 1;
            setMenuState({
                leistung: real === 0,
                projekte: real === 1
            })
            
        }
        // setInterval(onScroll, 200);
        const bounce = debounce(onScroll, 50, {maxWait: 50});
        window.addEventListener('scroll', bounce)
        return () => {
            window.removeEventListener('scroll', bounce);
            bounce.cancel();
        }
    }, [menuState])

    const {trans} = useTrans();
    
    return (
        <nav ref={ref} className="d-flex flex-row align-items-center cursor-pointer h-100">
            <span className={classNames(
                "d-none d-md-inline",
                {[containerStyles.active]: menuState.leistung}
            )}>
                <a className={"p-2 text-uppercase"} onClick={() => goToHome('leistung')}>
                    {trans({id: 'NAV.SERVICES', defaultMessage: 'Leistungen'})}
                </a>
            </span>
            <span className={classNames(
                "d-none d-md-inline",
                {[containerStyles.active]: menuState.projekte}
            )}>
                <a className={"p-2 text-uppercase"} onClick={() => goToHome('projekte')}>
                    {trans({id: 'NAV.PROJECTS', defaultMessage: 'Projekte'})}
                </a>
            </span>
            <span>
                <Link className="btn btn-outline-primary d-none d-sm-inline ms-3 p-2 text-uppercase" to="/kontakt/">
                    {trans({id: 'NAV.CONTACT', defaultMessage: 'KONTAKT'})}
                </Link>
            </span>
            <FullsizeMenu className="d-block d-md-none" goToBottom={goToBottom} goToHome={goToHome} />
        </nav>
    )
    
})
export default NavigationHome;
