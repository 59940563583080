import React, {useEffect, useState} from "react";
import * as styles from "./style.module.scss";
import {useGatsbyIntl} from "../../../plugins/gatsby-plugin-intl-custom/intl-context"
import classNames from "classnames";
import {Link as GatsbyLink} from "gatsby-link";
import browserLang from "browser-lang"
import {useTrans} from "@/src/trans";

const LanguageRecommendation = () => {
    
    if (typeof window == "undefined") return; // SSR
    
    const browserLanguage = browserLang({
        languages: ["en", "de"],
        fallback: "en"
    })
    
    const {trans} = useTrans();
    const {languageVersions, language} = useGatsbyIntl();
    
    const languageDiff = browserLanguage !== language;
    const [isExpanded, setExpanded] = useState(languageDiff);
    
    useEffect(() => {
        setExpanded(languageDiff)
    }, [languageDiff])
    
    
    return (
        <div className={classNames(
            "bg-dark-3 d-flex justify-content-center shadow-lg align-items-center",
            styles.host,
            {[styles.expanded]: isExpanded}
        )}>
            {browserLanguage === "de" && (
                trans({id: 'LANGUAGE_SWITCH.DE_PROMPT', defaultMessage: '🇩🇪 Diese Seite ist auch auf deutsch verfügbar'})
            )}
            {browserLanguage === "en" && (
                trans({id: 'LANGUAGE_SWITCH.EN_PROMPT', defaultMessage: '🇺🇸 This page is available in english'})
            )}
            
            <span className="ms-2">
                {language === "de" && languageVersions?.url_en && <GatsbyLink to={languageVersions.url_en} className="btn btn-primary btn-sm">
                    {trans({id: 'LANGUAGE_SWITCH.EN_ACTION', defaultMessage: 'switch'})}
                </GatsbyLink>}
                {language === "en" && languageVersions?.url_de && <GatsbyLink to={languageVersions.url_de} className="btn btn-primary btn-sm">
                    {trans({id: 'LANGUAGE_SWITCH.DE_ACTION', defaultMessage: 'wechseln'})}
                </GatsbyLink>}
            </span>
        
        </div>
    )
    
}

export default LanguageRecommendation;
