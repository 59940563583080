import React, {useState} from "react";
import { createPortal } from 'react-dom';
import classNames from "classnames";
import * as styles from "./style.module.scss"
import {useTrans} from "@/src/trans";
import Link from "@/plugins/gatsby-plugin-intl-custom/link"

const FullsizeMenu = ({className = "", goToHome, goToBottom}) => {
    
    const [isNavOpen, setNavOpen] = useState(false)
    const {trans} = useTrans();
    
    const closeBefore = (fn) => {
        setNavOpen(false);
        fn();
    }
    
    const portal = typeof window !== "undefined" ? createPortal(
        <div className={classNames(
            styles.backdrop,
            {[styles.active]: isNavOpen}
        )}>
            <div className={classNames(
                styles.inner,
                "w-100 h-100 d-flex justify-content-center"
            )}>
                
                <div className="d-flex flex-column align-items-center" style={{gap: 30}}>
                    <button onClick={() => closeBefore(() => goToHome('leistung'))}
                            className="btn btn-link fs-1 text-uppercase">
                        {trans({id: 'NAV.SERVICES', defaultMessage: 'Leistungen'})}
                    </button>
                    <button onClick={() => closeBefore(() => goToHome('skills'))}
                            className="btn btn-link fs-1 text-uppercase">
                        {trans({id: 'NAV.TECHNOLOGIES', defaultMessage: 'Technologien'})}
                    </button>
                    <button onClick={() => closeBefore(() => goToHome('projekte'))}
                            className="btn btn-link fs-1 text-uppercase">
                        {trans({id: 'NAV.PROJECTS', defaultMessage: 'Projekte'})}
                    </button>
                    <Link to="/kontakt/" onClick={() => closeBefore(() => null)} className="btn btn-link fs-1 text-uppercase">
                        {trans({id: 'NAV.CONTACT', defaultMessage: 'KONTAKT'})}
                    </Link>
                </div>
            
            </div>
        </div>,
        document.body
    ) : null

    return (
        <>
            <div onClick={() => setNavOpen(e => !e)}
                 style={{width: 70, height: 50}}
                 className={classNames(className, "position-relative ms-3")}>
                <a className={classNames(styles.menuToggle, {[styles.navOpen]: isNavOpen})}>
                    <span className={classNames(styles.menuToggleBar, styles.menuToggleBarTop)}></span>
                    <span className={classNames(styles.menuToggleBar, styles.menuToggleBarMiddle)}></span>
                    <span className={classNames(styles.menuToggleBar, styles.menuToggleBarBottom)}></span>
                </a>
            </div>
            {portal}
        </>
    )

}
export default FullsizeMenu;
