// extracted by mini-css-extract-plugin
export var active = "style-module--active--35c54";
export var bigBeforeScroll = "style-module--bigBeforeScroll--4c56e";
export var logo = "style-module--logo--e28e8";
export var navEnter = "style-module--navEnter--01ab3";
export var navEnterActive = "style-module--navEnterActive--c90e5";
export var navExit = "style-module--navExit--09364";
export var navExitActive = "style-module--navExitActive--c991f";
export var sizer = "style-module--sizer--714ec";
export var sticky = "style-module--sticky--41f27";
export var wrapper = "style-module--wrapper--15274";