import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from "react"
import {Link as GatsbyLink} from "gatsby"
import {navigate} from "../../../plugins/gatsby-plugin-intl-custom/link"
import Link from "../../../plugins/gatsby-plugin-intl-custom/link"
import {useGatsbyIntl} from "../../../plugins/gatsby-plugin-intl-custom/intl-context"
import {scroller, animateScroll} from "react-scroll";
import debounce from "loadsh/debounce";
import * as containerStyles from "./style.module.scss"
import classNames from "classnames";
import {useMediaQuery} from "react-responsive";
import {useTrans} from "../../trans";
import CookieConsent from "../cookie-consent/cookie-consent";
import LanguageRecommendation from "@/src/components/language-recommendation/language-recommendation";
import {TransitionGroup, CSSTransition} from "react-transition-group";

import "../../styles/bootstrap.scss"
import "../../styles/theme.scss"
import github from "../../../content/assets/images/icons/github.svg";
import logo from "../../../content/assets/images/logo.svg";
import name from "../../../content/assets/images/name.svg";
import linkedin from "../../../content/assets/images/icons/linkedin.svg";
import NavigationHome from "@/src/components/navigation-home";
import NavigationSimple from "@/src/components/navigation-simple";
import FullsizeMenu from "@/src/components/fullsize-menu/fullsize-menu";
import {SmartNavigationContext} from "@/src/components/layout/smart-navigation";

export const LayoutSize = createContext(false)
export const useLayoutSizeIsBig = () => React.useContext(LayoutSize);

const Layout: React.FC<PropsWithChildren<{
    location: any,
    pageContext: any
    navInitState?: 'big'|'small',
}>> = ({
    location,
    children,
    ...props
}) => {
    
    const layoutProps = {
        navTemplate: props.pageContext?.layout?.navTemplate || 'simple',
        scrollThreshold: props.pageContext?.layout?.scrollThreshold || 100,
        className: classNames(props.pageContext?.layout?.className) || "",
        navInitState: props.pageContext?.layout?.navInitState || "small",
    }
    
    const {trans} = useTrans();
    const {languageVersions, language} = useGatsbyIntl();
    
    
    const [activeNavTemplate, setActiveNavTemplate] = useState(
        layoutProps.navTemplate
    );
    
    useEffect(() => {
        setActiveNavTemplate(layoutProps.navTemplate)
    }, [layoutProps.navTemplate])
    
    const navTemplates = [{
        template: 'home',
        component: props => <NavigationHome
            {...props}
            goToHome={goToHome}
            goToBottom={goToBottom} />,
        ref: React.createRef()
    }, {
        template: 'simple',
        component: props => (
            <NavigationSimple {...props}
              goToHome={goToHome}
              goToBottom={goToBottom} />
        ),
        ref: React.createRef()
    }]
    
    const [sticky, setSticky] = useState(false)
    const bigMode = useMediaQuery({ query: '(min-width: 770px)' });
    
    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            let timer = setTimeout(() => {
                goToHome(location.state.scrollTo, false);
                window.history.replaceState({}, null)
            }, 250);
            return () => clearTimeout(timer);
        }
    }, [location.state])
    
    useEffect(() => {
        const onScroll = () => {
            const actual = window.scrollY
                > (bigMode ? layoutProps.scrollThreshold : 10);
            if (sticky !== actual) {
                setSticky(actual)
            }
        }
        const bounce = debounce(onScroll, 50, {maxWait: 50});
        window.addEventListener('scroll', bounce)
        return () => {
            window.removeEventListener('scroll', bounce);
            bounce.cancel();
        }
    }, [sticky])
    
    function goToHome(elem, smooth = true) {
        if (! ["/", "/en/"].includes(location.pathname)) {
            navigate("/", {state: {scrollTo: elem}});
        } else {
            scroller.scrollTo(elem, {
                smooth: smooth,
                offset: - document.getElementsByTagName('header')[0].getBoundingClientRect().height
            });
        }
    }
    
    function goToBottom() {
        animateScroll.scrollToBottom()
    }
    
    return (
        <div className={classNames(
            'layout',
            layoutProps.className,
            containerStyles.bigBeforeScroll,
            containerStyles.wrapper)}>
            
            <LanguageRecommendation />
            
            <header>
                
                <div className={classNames(
                    {[containerStyles.sticky]: sticky},
                    containerStyles.sizer,
                    "d-flex justify-content-center")}>
                
                    <div className="container-xl d-flex align-items-center w-100 h-100 justify-content-between">
                        
                        <Link to={"/"} className={classNames(
                            containerStyles.logo,
                            "align-items-center d-flex cursor-pointer"
                        )}>
                            <img alt={"logo"} src={logo} />
                        </Link>
                        
                        <Link to={"/"} className={classNames(
                            containerStyles.logo,
                            "align-items-center d-flex cursor-pointer flex-grow-0 flex-md-grow-1"
                        )}>
                            <span className="ms-0 ms-md-4 fw-normal pt-1 d-flex justify-content-center">
                                <img style={{width: 150}} alt={"tom esders"} src={name} />
                            </span>
                        </Link>
                        
                        {! bigMode && <FullsizeMenu className="d-block d-md-none"
                                      goToBottom={goToBottom}
                                      goToHome={goToHome} />}
                        
                        {bigMode && <TransitionGroup className="d-flex justify-content-end">
                            
                            {navTemplates.map(navigation => {
                                if (activeNavTemplate === navigation.template) {
                                    return (
                                        <CSSTransition
                                            key={navigation.template}
                                            nodeRef={navigation.ref}
                                            classNames={{
                                                enter: containerStyles.navEnter,
                                                enterActive: containerStyles.navEnterActive,
                                                exit: containerStyles.navExit,
                                                exitActive: containerStyles.navExitActive,
                                            }}
                                            timeout={750}>
                                            
                                            {navigation.component({ref: navigation.ref})}
                                        </CSSTransition>
                                    )
                                }
                            })}
                        </TransitionGroup>}
                        
                    </div>
                </div>
                
                {/*<button className="btn btn-primary" onClick={() => setActiveNavTemplate(e => e == 'home' ? 'simple' : 'home')}>switch</button>*/}
            </header>
            
            <SmartNavigationContext.Provider value={{
                goToBottom,
                goToHome
            }}>
                <LayoutSize.Provider value={bigMode}>
                    {children}
                </LayoutSize.Provider>
            </SmartNavigationContext.Provider>
            
            <footer>
                
                <div className="container-sm py-4">
                    <div className="text-uppercase">
                        <div className="py-4 d-flex flex-md-row flex-column align-items-center justify-content-between">
                            <div className={"d-flex flex-colmn align-items-center"}>
                                <span className="me-3">
                                    <a href="https://github.com/tomesders" target="_blank" rel="nofollow" className="nav-link ">
                                        <img alt={"github"} width={"40px"} src={github} />
                                    </a>
                                </span>
                                <span className="me-3">
                                    <a href="https://www.linkedin.com/in/tom-esders/" target="_blank" rel="nofollow" className="nav-link ">
                                        <img alt={"linkedin"} width={"40px"} src={linkedin} />
                                    </a>
                                </span>
                            </div>
                            <div className="d-flex flex-row flex-wrap justify-content-center mt-5 mt-md-0" style={{gap: '10px'}}>
                                <span className="me-4"><Link to={"/impressum/"} className="">{trans({id: 'FOOTER.IMPRINT', defaultMessage: "IMPRESSUM"})}</Link></span>
                                <span className="me-4"><Link to={"/datenschutz/"} className="">{trans({id: 'FOOTER.DATAPRIVACY', defaultMessage: "DATENSCHUTZ"})}</Link></span>
                                <span className="me-4">
                                    {language === "de" && languageVersions?.url_en && <GatsbyLink to={languageVersions.url_en}>ENGLISH VERSION</GatsbyLink>}
                                    {language === "en" && languageVersions?.url_de && <GatsbyLink to={languageVersions.url_de}>GERMAN VERSION</GatsbyLink>}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            
            <CookieConsent />
        
        </div>
    )
}

export default Layout
