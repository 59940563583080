import React, {useEffect, useState} from "react";
import classNames from "classnames";
import * as styles from "./style.module.scss"
import Link from "@/plugins/gatsby-plugin-intl-custom/link"
import {useTrans} from "@/src/trans";
import debounce from "lodash/debounce"

const CookieConsent = () => {
    
    const {trans} = useTrans();
    const [expanded, setExpanded] = useState(false);
    
    //
    // Nach 1 sek anzeigen
    //
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (! localStorage.getItem('cookie_consent')) {
                setExpanded(true);
            }
        }, 1000)
        return () => clearTimeout(timeout)
    }, [])
    
    // Scroll blendet consent aus
    useEffect(() => {
        if (localStorage.getItem('cookie_consent')) {
            return;
        }
        
        const onScroll = () => {
            if (window.scrollY > 800) {
                consent();
            }
        }
        
        const bounce = debounce(onScroll, 50, {maxWait: 50});
        window.addEventListener('scroll', bounce)
        return () => {
            window.removeEventListener('scroll', bounce);
            bounce.cancel();
        }
    }, [])
    
    const consent = () => {
        localStorage.setItem('cookie_consent', "1")
        setExpanded(false)
    }
    
    return (
        <div className={classNames(
            "shadow-lg d-flex flex-column flex-md-row justify-content-center p-3 align-items-center",
            styles.host,
            {[styles.expanded]: expanded}
        )}>
            
            <span className="fs-6">
                {trans({id: 'COOKIE_CONSENT.MESSAGE', defaultMessage: 'Diese Internetseite verwendet Cookies und Matomo für die Analyse und Statistik. Cookies helfen uns, die Benutzerfreundlichkeit unserer Website zu verbessern. Durch die weitere Nutzung der Website stimmen Sie der Verwendung zu. Weitere Informationen hierzu finden Sie in unserer:'})}
                <Link className="ms-2" to="/datenschutz">
                    {trans({id: 'COOKIE_CONSENT.DATA_PRICY', defaultMessage: 'Datenschutzerklärung'})}
                </Link>
                .
            </span>
            <button
                onClick={() => consent()}
                className="btn btn-outline-primary ms-0 ms-md-4 mt-3 mt-md-0">
                {trans({id: 'COOKIE_CONSENT.AGREE', defaultMessage: 'Verstanden'})}
            </button>
        
        </div>
    )
    
}

export default CookieConsent;
