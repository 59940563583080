
import React from "react";
import {useTrans} from "../trans";
import classNames from "classnames";
import * as containerStyles from "@/src/components/layout/style.module.scss";
import FullsizeMenu from "@/src/components/fullsize-menu/fullsize-menu";
import Link from "@/plugins/gatsby-plugin-intl-custom/link";

const NavigationSimple = React.forwardRef<HTMLElement, any>(
    ({
         goToHome,
         goToBottom
     }, ref) => {
     
    const {trans} = useTrans();
    
    return (
        <nav ref={ref} className="d-flex flex-row align-items-center cursor-pointer h-100">
            <a className={"p-2 text-uppercase d-none d-md-block"} onClick={() => goToHome('leistung')}>
                {trans({id: 'NAV.MORE_INFORMATION', defaultMessage: 'Weitere Leistungen'})}
            </a>
            <Link className="btn btn-outline-primary d-none d-sm-inline ms-3 p-2 text-uppercase" to="/kontakt/">
                {trans({id: 'NAV.CONTACT', defaultMessage: 'KONTAKT'})}
            </Link>
            <FullsizeMenu goToBottom={goToBottom} goToHome={goToHome} />
        </nav>
    )
    
})
export default NavigationSimple;
